<template>
	<div class="ui" @keypress="handleKeyPressed">
		<div class="ui-nav">
			<div class="ui-nav-prev" @click="webGLApp.showPrevScene()"></div>
			<div class="ui-nav-next" @click="webGLApp.showNextScene()"></div>
		</div>

		<div class="ui-number">
			<h1>{{ sceneIndex }}</h1>
		</div>
	</div>
</template>

<script>
import { inject, onMounted, watchEffect, /*watch,*/ ref } from 'vue';
import * as Helpers from '@/utils/helpers.js';

export default {
	name: 'UIComp',
	// props: {
	// 	pressedRemoteKey: {
	// 		type: String,
	// 		required: true,
	// 	},
	// },
	//setup(props) {

	// watch(
	// 	() => props.pressedRemoteKey,
	// 	(key) => {
	// 		switch (key) {
	// 			case '1':
	// 				onNext();
	// 				break;
	// 			case 'arrowLeft':
	// 				onPrev();
	// 				break;
	// 			case 'arrowRight':
	// 				onNext();
	// 				break;
	// 			case 'undefined':
	// 				onPrev();
	// 				break;
	// 			default:
	// 				break;
	// 		}
	// 	},
	// );

	setup(props, { emit }) {
		const store = inject('store');
		const webGLApp = store.getters.webGLInstance();
		const sceneIndex = ref(null);

		function onKeydown(event) {
			//Prevents firing multiple time
			if (event.repeat) return;
			console.log(event.code);
			switch (event.code) {
				case 'ArrowLeft':
					webGLApp.showPrevScene();
					break;
				case 'ArrowRight':
					webGLApp.showNextScene();
					break;
				case 'Digit0':
					webGLApp.onShowScene(0);
					break;
				case 'Digit1':
					webGLApp.onShowScene(1);
					break;
				case 'Digit2':
					webGLApp.onShowScene(2);
					break;
				case 'Digit3':
					webGLApp.onShowScene(3);
					break;
				case 'KeyW':
					store.actions.setKeyWIsDown(true);
					break;
				case 'KeyA':
					store.actions.setKeyAIsDown(true);
					break;
				case 'KeyS':
					store.actions.setKeySIsDown(true);
					break;
				case 'KeyD':
					store.actions.setKeyDIsDown(true);
					break;
				case 'NumpadAdd':
					emit('on-volume-key-pressed', 1);
					break;
				case 'NumpadSubtract':
					emit('on-volume-key-pressed', -1);
					break;
				case 'Enter':
					webGLApp.onShowScene(1);
					break;
				case 'KeyM':
					webGLApp.onchangeBrighntess(0.01);
					break;
				case 'KeyN':
					webGLApp.onchangeBrighntess(-0.01);
					break;
				case 'KeyC':
					webGLApp.onchangeContrast(0.01);
					break;
				case 'KeyV':
					webGLApp.onchangeContrast(-0.01);
					break;
				default:
					console.log('[UI_comp] onKEyDown key not found: ', event.code);
					break;
			}
		}

		function onKeyup(event) {
			switch (event.code) {
				case 'KeyW':
					store.actions.setKeyWIsDown(false);
					break;
				case 'KeyA':
					store.actions.setKeyAIsDown(false);
					break;
				case 'KeyS':
					store.actions.setKeySIsDown(false);
					break;
				case 'KeyD':
					store.actions.setKeyDIsDown(false);
					break;
				case 'Enter':
					store.actions.setKeyOkIsDown(false);
					break;
				default:
					console.log('[UI_comp] onKeyup key not found: ', event.code);
					break;
			}
		}

		function onRemoteKeyPressed(remoteKey) {
			switch (remoteKey) {
				case '1':
					webGLApp.onShowScene(1);
					break;
				case 'arrowLeft':
					webGLApp.showPrevScene();
					break;
				case 'arrowRight':
					webGLApp.showNextScene();
					break;
				case 'undefined':
					webGLApp.onShowScene(1);
					break;
				default:
					console.log('[UI_comp] onRemoteKeyPressed remoteKey not found: ', remoteKey);
					break;
			}
		}

		watchEffect(() => {
			sceneIndex.value = Helpers.minTwoDigits(store.getters.activeSceneIndex());

			if (sceneIndex.value === '00') {
				sceneIndex.value = '';
			}

			emit('on-scene-index-changed', store.getters.activeSceneIndex());
		});

		onMounted(() => {
			document.addEventListener('keydown', (event) => onKeydown(event));
			document.addEventListener('keyup', (event) => onKeyup(event));
		});

		return {
			webGLApp,
			sceneIndex,
			onRemoteKeyPressed,
		};
	},
};
</script>

<style lang="scss">
.ui {
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&-nav {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 60px;
		display: flex;
		pointer-events: auto;

		&-prev {
			//background: rgba(128, 128, 128, 0.5);
			width: 50%;
		}
		&-next {
			width: 50%;
			//background: rgba(128, 0, 128, 0.5);
		}
	}

	&-number {
		position: absolute;
		right: 130px;
		top: 30px;
		color: white;
		mix-blend-mode: difference;
		font-size: 40px;
	}
}
</style>
