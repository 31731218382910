<template>
	<div></div>
</template>

<script>
import { onMounted } from 'vue';
import { Howl, Howler } from 'howler';

export default {
	name: 'SoundComp',
	setup() {
		const audioPath = `${process.env.BASE_URL}staticAssets/audios/`;
		let musics = [
			'music_scene_0.mp3',
			'music_scene_1.mp3',
			'music_scene_2.mp3',
			'music_scene_3.mp3',
			'music_scene_4.mp3',
			'music_scene_5.mp3',
			'music_scene_6.mp3',
			'music_scene_7.mp3',
		];
		const sounds = [];
		let activeSoundIndex = 0;
		let activeVolume = 0.5;

		function clamp(num, min, max) {
			return Math.min(Math.max(num, min), max);
		}

		function cahngeVolume(value) {
			activeVolume = clamp(activeVolume + value * 0.1, 0.0, 1.0);
			Howler.volume(activeVolume);
		}

		function changeSound(index) {
			sounds[activeSoundIndex].pause();
			activeSoundIndex = index;
			sounds[activeSoundIndex].play();
		}

		onMounted(() => {
			musics.forEach((element) => {
				const sound = new Howl({
					src: [audioPath + element],
					loop: true,
				});

				sounds.push(sound);
			});

			Howler.volume(activeVolume);

			sounds[activeSoundIndex].play();
		});

		return {
			cahngeVolume,
			changeSound,
		};
	},
};
</script>

<style lang="scss"></style>
