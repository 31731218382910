<template>
	<div></div>
</template>

<script>
import { inject, onMounted } from 'vue';
import { io } from 'socket.io-client';

export default {
	name: 'SocketComp',

	setup(props, { emit }) {
		const isDebugActive = window.location.hash === '#debug' ? true : false;
		const store = inject('store');
		const webGLApp = store.getters.webGLInstance();
		let timer;
		let socket;

		if (isDebugActive || process.env.NODE_ENV === 'production') {
			socket = io('http://127.0.0.1:3000', {
				transports: ['websocket', 'polling', 'flashsocket'],
			});
		}

		function startKeyUpChecker(keyCode) {
			clearTimeout(timer);
			timer = setTimeout(() => {
				onKeyUp(keyCode);
			}, 150);
		}

		function onKeyUp(keyCode) {
			switch (keyCode) {
				case 'Arrow UP':
					store.actions.setKeyWIsDown(false);
					break;
				case 'Arrow Down':
					store.actions.setKeySIsDown(false);
					break;
				case 'Arrow Left':
					store.actions.setKeyAIsDown(false);
					break;
				case 'Arrow Right':
					store.actions.setKeyDIsDown(false);
					break;
				default:
					console.log('[Socket_comp] onKeyup key not found: ', keyCode);
					break;
			}
		}

		function onKeyDown(keyCode) {
			switch (keyCode) {
				case 'Arrow Up':
					store.actions.setKeyWIsDown(true);
					startKeyUpChecker('Arrow UP');
					break;
				case 'Arrow Down':
					store.actions.setKeySIsDown(true);
					startKeyUpChecker('Arrow Down');
					break;
				case 'Arrow Left':
					store.actions.setKeyAIsDown(true);
					startKeyUpChecker('Arrow Left');
					break;
				case 'Arrow Right':
					store.actions.setKeyDIsDown(true);
					startKeyUpChecker('Arrow Right');
					break;
				case 'Ok':
					webGLApp.showNextScene();
					break;
				case 'number 1':
					webGLApp.onShowScene(1);
					socket.emit('onActiveSceneChanged', 1);
					break;
				case 'number 2':
					webGLApp.onShowScene(2);
					socket.emit('onActiveSceneChanged', 2);
					break;
				case 'number 3':
					webGLApp.onShowScene(3);
					socket.emit('onActiveSceneChanged', 3);
					break;
				case 'number 4':
					webGLApp.onShowScene(4);
					socket.emit('onActiveSceneChanged', 4);
					break;
				case 'number 5':
					webGLApp.onShowScene(5);
					socket.emit('onActiveSceneChanged', 5);
					break;
				case 'number 6':
					webGLApp.onShowScene(6);
					socket.emit('onActiveSceneChanged', 6);
					break;
				case 'number 7':
					webGLApp.onShowScene(7);
					socket.emit('onActiveSceneChanged', 7);
					break;
				case 'number 8':
					webGLApp.onShowScene(7);
					socket.emit('onActiveSceneChanged', 7);
					break;
				case 'number 9':
					webGLApp.onShowScene(7);
					socket.emit('onActiveSceneChanged', 7);
					break;
				case 'Power':
					webGLApp.onShowScene(0);
					socket.emit('onActiveSceneChanged', 0);
					break;
				case 'Stop':
					emit('on-volume-key-pressed', -1);
					break;
				case 'Text':
					emit('on-volume-key-pressed', 1);
					break;

				default:
					console.log('[Socket_comp] onKEyDown key not found: ', keyCode);
					break;
			}
		}

		onMounted(() => {
			if (isDebugActive || process.env.NODE_ENV === 'production') {
				//if (process.env.NODE_ENV === 'development') return;
				store.actions.addSocketInstance(socket);

				socket.on('onRemoteKeyTriggered', (data) => {
					onKeyDown(data);
				});

				// client-side
				socket.on('connect', () => {
					console.log('Connected to socket'); // x8WIv7-mJelg7on_ALbx
					socket.emit('onActiveSceneChanged', 0);
				});

				socket.on('disconnect', () => {
					console.log('Disconnected from socket'); // undefined
					webGLApp.onShowScene(0);
				});
			}
		});

		return {};
	},
};
</script>

<style lang="scss">
.socket {
	position: absolute;
	z-index: 12121;
	width: 100px;
	height: 100px;
	pointer-events: none;
	background: pink;
}
</style>
