<template>
	<SocketComp @on-volume-key-pressed="onVolumeKeyPressed" />
	<UIComp
		ref="uiComp"
		@on-scene-index-changed="onSceneIndexChanged"
		@on-volume-key-pressed="onVolumeKeyPressed"
	/>
	<Sound ref="soundComp" />
	<!-- <SerialAPIComp @on-remote-key-pressed="onRemoteKeyPressed" /> -->
	<!-- <UIComp ref="uiComp" :pressedRemoteKey="pressedRemoteKey" /> -->
</template>

<script>
import { ref } from 'vue';
import UIComp from '@/components/ui/UI_comp.vue';
import Sound from '@/components/sound/Sound_comp.vue';
import SocketComp from '@/components/socket/Socket_comp.vue';
//import SerialAPIComp from '@/components/serialAPI/SerialAPI_comp.vue';

export default {
	name: 'Home',
	components: {
		UIComp,
		Sound,
		SocketComp,
		//SerialAPIComp,
	},
	setup() {
		const uiComp = ref(null);
		const soundComp = ref(null);
		// const pressedRemoteKey = ref('');

		function onRemoteKeyPressed(remoteKey) {
			//pressedRemoteKey.value = key;
			uiComp.value.onRemoteKeyPressed(remoteKey);
		}

		function onVolumeKeyPressed(value) {
			soundComp.value?.cahngeVolume(value);
		}

		function onSceneIndexChanged(index) {
			soundComp.value?.changeSound(index);
		}

		return {
			onRemoteKeyPressed,
			onVolumeKeyPressed,
			onSceneIndexChanged,
			uiComp,
			soundComp,
			//pressedRemoteKey,
		};
	},
};
</script>
